import React, { Suspense } from "react";
import { connect } from "react-redux";

const SignUpComponent = React.lazy(() =>
  import("../../components/app/Authentication/SignUp")
);
import * as actions from "../../actions/user";
const SignUp = (props) => {
  return (
    <Suspense fallback={<></>}>
      <SignUpComponent {...props} />
    </Suspense>
  );
};

export default connect(({ user }) => ({ user }), actions)(SignUp);
