import { combineReducers } from 'redux';

import community from './community';
import communityDetails from './community-details';
import communityListings from './community-listings';
import communityDonate from './community-donate';

export default combineReducers({
  community,
  communityDetails,
  communityListings,
  communityDonate
});
