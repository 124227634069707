import React,{useState} from 'react'
import { imageURL } from '../constants/constants';
import { connect } from 'react-redux';
import * as actions from '../actions/newsDetail';

function Feeds({subscribe}, props) {
    const [email,setEmail]=useState('');

  return (
    <div> 
        <h2 className="main-heading mb-3">Subscribe to our newsletter</h2>
                <p className="newsletter-text">You’ll receive updates, tips and information on fundraising and campaigns.</p>
                <div id='custom-search-input'>
                  <div className='input-group mb-4'>
                    <input
                      type='email'
                      required
                      className='  search-query form-control'
                      placeholder='Your Email'
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                    <span className='input-group-btn'>
                      <button className='btn btn-success px-3' onClick={() => {
                  subscribe({ email }).then(() => setEmail(''));
                }} type='button'>
                        <img src={`${imageURL}footer-right-arrow.png`} style={{ objectFit: 'cover', width: '100%', height: '100%' }} className="img-fluid" alt="" loading="lazy" ></img>
                      </button>
                    </span>
                  </div>
                </div>
            </div>
  )
}

export default connect(({ user, donateMoney }) => ({ user, donateMoney }), actions)(Feeds);