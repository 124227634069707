const initialState = {
  loading: false,
  school: {}
};

const schoolDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FEATURED_SCHOOL_SUCCESS':
    case 'GET_FEATURED_SCHOOL_FAILED':
    case 'GET_FEATURED_SCHOOL_REQUEST':
    case 'SHARE_SCHOOL_REQUEST':
    case 'SHARE_SCHOOL_SUCCESS':
    case 'SHARE_SCHOOL_FAILED':
    case 'SUBSCRIBE_SCHOOL_DETAILS_REQUEST':
    case 'SUBSCRIBE_SCHOOL_DETAILS_FAILED':
    case 'SUBSCRIBE_SCHOOL_DETAILS_SUCCESS':
    case 'LIKE_COMPANY_SCHOOL_REQUEST':
    case 'LIKE_COMPANY_SCHOOL_SUCCESS':
    case 'LIKE_COMPANY_SCHOOL_FAILED':
    case 'FOLLOW_COMPANY_SCHOOL_REQUEST':
    case 'FOLLOW_COMPANY_SCHOOL_FAILED':
    case 'FOLLOW_COMPANY_SCHOOL_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_SCHOOL_DETAILS_REQUEST':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_SCHOOL_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_SCHOOL_DETAILS_FAILED':
    case 'ENQUIRE_QUERY_SCHOOL_FORM_FAILED':
    case 'ENQUIRE_QUERY_SCHOOL_FORM_REQUEST':
    case 'ENQUIRE_QUERY_SCHOOL_FORM_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_SCHOOL_DETAILS_REQUEST':
    case 'GET_CUSTOMER_REVIEWS_FOR_SCHOOL_DETAILS_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_SCHOOL_DETAILS_FAILED':
    case 'GET_SCHOOL_BY_ID_FOR_SCHOOL_DETAILS_PAGE_REQUEST':
    case 'GET_SCHOOL_BY_ID_FOR_SCHOOL_DETAILS_PAGE_FAILED':
    case 'GET_SCHOOL_BY_ID_FOR_SCHOOL_DETAILS_PAGE_SUCCESS': {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default schoolDetails;
