import React, { useState, useRef, useEffect } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { connect } from 'react-redux';
import { Menu, Button, Dropdown } from 'antd';

import * as actions from '../../actions/user';
import { useHistory, useParams } from 'react-router';
import { imageURL } from '../../constants/constants';
import { Helmet } from "react-helmet";


const clientId =
  '211459604873-h9f19nhomvsum9ios12bj3saei98vdfr.apps.googleusercontent.com';

function closeNav() {
  Array.from(document.querySelectorAll('.mbl-drop-down.show')).forEach(function (el) {
    el.classList.remove('show');
  });
}
const handleActiveBtn = (route, link) => {
  if (route.location.pathname == link) {
    return 'active-btn'
  }
}

const handleActiveBtnText = (route, link) => {
  if (route.location.pathname == link) {
    return 'white-text'
  }
}


const fundRaiserMenu = (history, userCheckAuth) => (
  <Menu>
    <Menu.Item key={1} className={handleActiveBtn(history, '/school')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/school') }} type='link'>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/school')} type='link' >
        School
      </Button>
    </Menu.Item>

    <Menu.Item key={2} className={handleActiveBtn(history, '/sports')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/sports') }} type='link'>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/sports')} type='link'>
        Sport Club
      </Button>
    </Menu.Item>
    <Menu.Item key={3} className={handleActiveBtn(history, '/charity')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/charity') }} >
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/charity')} type='link' >
        Charities
      </Button>
    </Menu.Item>
    <Menu.Item key={4} className={handleActiveBtn(history, '/community')} onClick={() => { userCheckAuth(); history.push('/community') }} style={{ padding: 0 }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/community')} type='link' >
        Communities
      </Button>
    </Menu.Item>
    <Menu.Item key={5} className={handleActiveBtn(history, '/event-manager')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/event-manager') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/event-manager')} type='link'>
        Events
      </Button>
    </Menu.Item>
  </Menu>
);

const howItWorksMenu = (history, userCheckAuth) => (
  <Menu>

    <Menu.Item key={1} className={handleActiveBtn(history, '/why-icause')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/why-icause') }} >
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/why-icause')} type='link' >
        Why Icause
      </Button>
    </Menu.Item>
    <Menu.Item key={2} className={handleActiveBtn(history, '/guarantee')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/guarantee') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/guarantee')} type='link' >
        Icause Guarantee
      </Button>
    </Menu.Item>
    <Menu.Item key={3} className={handleActiveBtn(history, '/auth/login')} style={{ padding: 0 }} onClick={() => history.push('/auth/login')} >
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/auth/login')} type='link' >
        Sign in/ Signup
      </Button>
    </Menu.Item>
  </Menu>
);

const donateForMenu = (history, userCheckAuth) => (
  <Menu>
    <Menu.Item key={1} className={handleActiveBtn(history, '/health/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/health/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/health/startFundraising')} type='link' >
        Health & Medical
      </Button>
    </Menu.Item>
    <Menu.Item key={2} className={handleActiveBtn(history, '/animals/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/animals/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/animals/startFundraising')} type='link' >
        Animal & Pets
      </Button>
    </Menu.Item>
    <Menu.Item key={3} className={handleActiveBtn(history, '/accident/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/accident/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/accident/startFundraising')} type='link' >
        Accident
      </Button>
    </Menu.Item>
    <Menu.Item key={4} className={handleActiveBtn(history, '/bucketlist/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/bucketlist/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/bucketlist/startFundraising')} type='link'  >
        Bucket List
      </Button>
    </Menu.Item>
    <Menu.Item key={5} className={handleActiveBtn(history, '/inmemory/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/inmemory/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/inmemory/startFundraising')} type='link' >
        In Memory
      </Button>
    </Menu.Item>
    <Menu.Item key={6} className={handleActiveBtn(history, '/environment/startFundraising')} onClick={() => { userCheckAuth(); history.push('/environment/startFundraising') }} style={{ padding: 0 }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/environment/startFundraising')} type='link' >
        Environment
      </Button>
    </Menu.Item>
    <Menu.Item key={7} className={handleActiveBtn(history, '/travel/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/travel/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/travel/startFundraising')} type='link' >
        Travel & Tourism
      </Button>
    </Menu.Item>
    <Menu.Item key={8} className={handleActiveBtn(history, '/faith/startFundraising')} onClick={() => { userCheckAuth(); history.push('/faith/startFundraising') }} style={{ padding: 0 }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/faith/startFundraising')} type='link' >
        Faith
      </Button>
    </Menu.Item>
  </Menu >
);


const knowledgeDropDown = (history, userCheckAuth) => (
  <Menu>
    <Menu.Item key={1} className={handleActiveBtn(history, '/health/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/health/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/health/startFundraising')} type='link' >
        White Papers
      </Button>
    </Menu.Item>
    <Menu.Item key={2} className={handleActiveBtn(history, '/animals/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/animals/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/animals/startFundraising')} type='link' >
        Eguide
      </Button>
    </Menu.Item>
    <Menu.Item key={3} className={handleActiveBtn(history, '/accident/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/accident/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/accident/startFundraising')} type='link' >
        Case Studies
      </Button>
    </Menu.Item>
    <Menu.Item key={4} className={handleActiveBtn(history, '/bucketlist/startFundraising')} style={{ padding: 0 }} onClick={() => { userCheckAuth(); history.push('/bucketlist/startFundraising') }}>
      <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" className={handleActiveBtnText(history, '/bucketlist/startFundraising')} type='link'  >
        Videos
      </Button>
    </Menu.Item>

  </Menu >
);

const WelcomeDropdwn = (history, logout, signOut) => (
  <Menu>

    <Menu.Item key={1} style={{ padding: 0 }}>
      <Button href={`${process.env.REACT_APP_BACKEND_BASE_URL}cabinet`} type='link'>
        Profile
      </Button>
    </Menu.Item>
    <Menu.Item key={2} style={{ padding: 0 }}>
      <Button href={`${process.env.REACT_APP_BACKEND_BASE_URL}`} type='link'>
        Go to Dashboard
      </Button>
    </Menu.Item>
    <Menu.Item key={3} style={{ padding: 0 }}>
      <Button type='link' onClick={() => {
        signOut()
        logout();
      }}>
        Logout
      </Button>
    </Menu.Item>

  </Menu>
);

const token = localStorage.getItem("loginToken");

const Header = (props) => {
  const { user, logout, checkUserLoginStatusOnDashboard, isMobile, getUser } = props;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [userToken, setUserToken] = useState(null);
  const [inputClassName, setInputClassName] = useState(null);
  const [checkUser, setCheckUser] = useState(true);
  const [sidebar, setSideBar] = useState(false);
  const inputRef = useRef(null);
  const history = useHistory();
  const onGoogleLogoutSuccess = (res) => {
  };
  const [loadingForFetchingUser, setLoadingForFetchingUser] = useState(false);

  useEffect(() => {
    if (token && (!user || !user.id)) {
      setLoadingForFetchingUser(true);
      getUser(token)
        .then((data1) => {
          checkUserLoginStatusOnDashboard(data1?.payload)
            .then((data) => {
              if (data?.user?.login_status == 0) {
                signOut();
                logout();
              }

            })
            .catch(() => setLoadingForFetchingUser(false))
        })
        .catch(() => setLoadingForFetchingUser(false))
    }
  }, [])

  const onGooleLogoutFailure = () => {
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onGoogleLogoutSuccess,
    onGooleLogoutFailure
  });

  const getEnterKeyOnInput = (event) => {
    if (searchKeyword === '') return;
    if ((event === "searchIcon" || event.key === 'Enter') && searchKeyword) {
      setSearchKeyword('')
      redirectToPage(`/searched-campaigns/${searchKeyword}`);
    }
  }
  let userImage = null;
  if (user && user.id) {
    if (user.avatar) {
      userImage = user.avatar
    } else {
      userImage = `${imageURL}avatar.png`;
    }
  }

  const redirectToPage = (url) => {
    history.push(url)
  }

  useEffect(() => {
    if (user && user.id && userToken && checkUser
      && history.location.pathname !== "/auth/login"
      && history.location.pathname !== "/auth/verification"
    ) {
      setCheckUser(false);
    }
    else {
      if (localStorage.getItem("loginToken") && !user.loginToken) {
        setUserToken(localStorage.getItem("loginToken"))
      }
      else {
        if (user.loginToken) {
          setUserToken(user.loginToken)
          return
        }
        setUserToken(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUser, user, userToken])

  const checkUserLoginStatus = (user) => {
    checkUserLoginStatusOnDashboard(user).then((status) => {
      let login_status = status?.user?.login_status;
      if (login_status) {
      } else {
        logout();
        signOut();
      }
    })
  }

  const userCheckAuth = () => setCheckUser(true)
  const [isTab, setIsTab] = useState(true)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth > 720 && window.innerWidth <= 1283) {
      setIsTab(true)
    } else {
      setIsTab(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const structuredJSON = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://icause.com.au/"
    }, {
      "@type": "ListItem",
      "position": 2,
      "name": "School",
      "item": "https://icause.com.au/school"
    }, {
      "@type": "ListItem",
      "position": 3,
      "name": "Sports",
      "item": "https://icause.com.au/sports"
    }, {
      "@type": "ListItem",
      "position": 4,
      "name": "Charity",
      "item": "https://icause.com.au/charity"
    }, {
      "@type": "ListItem",
      "position": 5,
      "name": "Why I-cause",
      "item": "https://icause.com.au/why-icause"
    }, {
      "@type": "ListItem",
      "position": 6,
      "name": "Login/Signup",
      "item": "https://icause.com.au/auth/login"
    }, {
      "@type": "ListItem",
      "position": 7,
      "name": "Health Fundraising",
      "item": "https://icause.com.au/health/startFundraising"
    }, {
      "@type": "ListItem",
      "position": 8,
      "name": "Animals Fundraising",
      "item": "https://icause.com.au/animals/startFundraising"
    }, {
      "@type": "ListItem",
      "position": 9,
      "name": "Accident",
      "item": "https://icause.com.au/accident/startFundraising"
    }, {
      "@type": "ListItem",
      "position": 10,
      "name": "Blogs",
      "item": "https://icause.com.au/blogs"
    }, {
      "@type": "ListItem",
      "position": 11,
      "name": "FAQs",
      "item": "https://icause.com.au/faq#!"
    }, {
      "@type": "ListItem",
      "position": 12,
      "name": "Securtiy",
      "item": "https://icause.com.au/security#!"
    }]
  }

  return (
    <div id="scrollTo">

      <Helmet>
        <script type="application/ld+json">{`${structuredJSON}`}</script>
        <htmlAttributes lang="en" />
        <nav class="firstNav">
          <ul itemscope itemtype="https://schema.org/SiteNavigationElement" role="menu">
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/" title="Home">Home</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/school" title="School">School</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/sports" title="Sports">Sports</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/charity" title="Charity">Charity</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/community" title="Community">Community</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/blogs" title="Blog">Blog</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/event-manager" title="Events">Events</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/contact-us#!" title="Contact">Contact</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/about#!" title="About Us">About Us</a></li>
            <li itemprop="name" role="menuitem"><a href="https://icause.com.au/why-icause#!" title="Why Icause">Why Icause</a></li>
          </ul>
        </nav>


      </Helmet>
      <header id="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container-fluid">
            <div className="d-flex align-items-center mb-2 mb-sm-0">
              <Button onClick={() => history.push('/')} className="navbar-brand" type='link'>
                <img src={`${imageURL}logo.png`} className="img-fluid" alt="" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
              </Button>
              {!isMobile && <div id="expanding-search">
                <span
                  onClick={(e) => {
                    getEnterKeyOnInput('searchIcon');
                    setInputClassName('inputexpended');
                    inputRef.current.focus();
                  }}
                  className="search-icon-expand search--icon">
                  <svg id="search_1_" data-name="search (1)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="Group_6443" data-name="Group 6443" transform="translate(0)">
                      <path id="Path_36602" data-name="Path 36602" d="M.122,18.7l5.81-5.81A7.932,7.932,0,1,1,7.11,14.068L1.3,19.878a.417.417,0,0,1-.589,0l-.589-.589A.417.417,0,0,1,.122,18.7Zm11.961-4.533a6.25,6.25,0,1,0-6.25-6.25A6.257,6.257,0,0,0,12.083,14.167Z" transform="translate(0 0)" />
                    </g>
                  </svg>
                </span>

                <input
                  ref={inputRef}
                  className={inputClassName}
                  value={searchKeyword}
                  type="search"
                  placeholder="search"
                  onKeyDown={(e) => { getEnterKeyOnInput(e) }}
                  onChange={(event) => {
                    setSearchKeyword(event.target.value)
                  }}
                  onBlur={() => { setInputClassName(''); }}
                />

              </div>}
            </div>
            <button className="navbar-toggler" onClick={closeNav} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto ">

                <li className="nav-item ">
                  <Dropdown overlay={() => fundRaiserMenu(history, userCheckAuth)}>
                    <a href='dsa' style={{ fontSize: 15, marginRight: 50, color: 'black' }} className="ant-dropdown-link" onClick={e => { setCheckUser(true); e.preventDefault() }}>
                      Fundraise
                    </a>
                  </Dropdown>
                </li>

                <li className="nav-item">
                  <Dropdown overlay={() => howItWorksMenu(history, userCheckAuth)}>
                    <a href='dsa' style={{ fontSize: 15, marginRight: 50, color: 'black' }} className="ant-dropdown-link" onClick={e => { setCheckUser(true); e.preventDefault() }}>
                      How It Works?
                    </a>
                  </Dropdown>
                </li>

                <li className="nav-item">
                  <Dropdown overlay={() => donateForMenu(history, userCheckAuth)}>
                    <a href='dsa' style={{ fontSize: 15, marginRight: 30, color: 'black' }} className="ant-dropdown-link" onClick={e => { setCheckUser(true); e.preventDefault() }}>
                      Donate For
                    </a>
                  </Dropdown>
                </li>
                <span className="nav-pipe">|</span>
                <li className={`${handleActiveBtn(history, '/blogs')} nav-item blog-nav`}>
                  <Button style={{ paddingTop: '0px' }} onClick={() => { setCheckUser(true); history.push('/blogs') }} className={`${handleActiveBtnText(history, '/blogs')} nav-link`} type='link'>
                    Blog
                  </Button>
                </li>
                {(user && user.id && user.loginToken && history.location.pathname !== "/auth/login") &&
                  token &&
                  <li className="nav-item pro-dd-li">
                    <Dropdown overlay={() => WelcomeDropdwn(history, logout, signOut)}>
                      <a href='dsa' style={{ fontSize: 15, marginRight: 30, color: 'black' }} className="ml-2 ant-dropdown-link profile-seting" onClick={e => { setCheckUser(true); e.preventDefault() }}>
                        {`Hi ${user.first_name}`}
                      </a>
                    </Dropdown>
                  </li>
                }
              </ul>
              <button className="btn btn-success ml-2 my-sm-0 text-uppercase custom-height custom-btn header-signup " onClick={() => history.push(
                "/auth/signup"
              )}>Start Fundraising  </button>
              &nbsp;&nbsp;&nbsp;
              {(!userToken || history.location.pathname === "/auth/login") &&
                <div className='hover-opction'>
                  <button
                    onClick={() => {
                      history.push('/auth/login')
                    }}
                    style={{ padding: '0px 50px', width: isTab ? '157px' : '100%' }} className="header-login btn btn-success my-2  my-sm-2  text-uppercase custom-height custom-btn-style "
                  >
                    Join Us
                  </button>
                  <div className='hover-view'>
                    <ul>
                      <li className={history.location.pathname == '/auth/login' ? "active-btn" : ''}><a className={history.location.pathname == '/auth/login' ? "white-text" : ''} onClick={() => { history.push('/auth/login') }}>Login</a></li>
                      <li className={history.location.pathname == '/auth/signup' ? "active-btn" : ''}><a className={history.location.pathname == '/auth/signup' ? "white-text" : ''} onClick={() => history.push('/auth/signup')}>Sign Up</a></li>
                    </ul>
                  </div>
                </div>
              }
            </div>
            <div style={{ paddingTop: '8px' }} className={user && user.id ? 'collapse navbar-collapse mobile-menu loggedIn ' : 'collapse navbar-collapse mobile-menu loggedOut'} id="navbarSupportedContent2">
              <ul className="navbar-nav ml-auto ">
                {user && user.id &&
                  <li className="nav-item">
                    <img className="w-100" src={userImage} alt="userImage" />
                  </li>
                }
                <li className='mob-logo nav-item'> <span><img src="https://icause.s3.us-east-2.amazonaws.com/app_images/logo.png"></img></span></li>
                <li className="nav-item ">
                  <span className="mbl-menu-close" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2">&times;</span>
                  <a data-bs-toggle="collapse" data-bs-target="#funddrop" href='dsa' style={{ fontSize: 15, marginRight: 50, color: 'black' }} className="ant-dropdown-link" onClick={e => { userCheckAuth(); e.preventDefault() }}>
                    Fundraise
                  </a>
                  <ul className="mbl-drop-down collapse" id="funddrop">
                    {fundRaiserMenu(history, userCheckAuth)}
                  </ul>
                </li>

                <li className="nav-item">
                  <a data-bs-toggle="collapse" data-bs-target="#howwork" href='dsa' style={{ fontSize: 15, marginRight: 50, color: 'black' }} className="ant-dropdown-link" onClick={e => { userCheckAuth(); e.preventDefault() }}>
                    How It Works?
                  </a>
                  <ul className="mbl-drop-down collapse" id="howwork">
                    {howItWorksMenu(history, userCheckAuth)}
                  </ul>
                </li>
                <li className="nav-item">
                  <a data-bs-toggle="collapse" data-bs-target="#donatfor" href='dsa' style={{ fontSize: 15, marginRight: 30, color: 'black' }} className="ant-dropdown-link" onClick={e => { userCheckAuth(); e.preventDefault() }}>
                    Donate For
                  </a>
                  <ul className="mbl-drop-down collapse" id="donatfor">
                    {donateForMenu(history, userCheckAuth)}
                  </ul>
                </li>
                <li className=" border-0 nav-item">
                  <Button data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" style={{ paddingTop: '0px' }} onClick={() => { userCheckAuth(); history.push('/blogs') }} className="nav-link" type='link'>
                    Blog
                  </Button>
                </li>
                <li className="nav-item mobie-serach">
                  <div className='row align-items-center'>
                    <div className='col-10'>
                      <div className="form-group m-0">
                        <input
                          ref={inputRef}
                          className={"form-control"}
                          value={searchKeyword}
                          type="search"
                          placeholder="Search"
                          onChange={(event) => {
                            setSearchKeyword(event.target.value)
                          }}
                          onBlur={() => setInputClassName('')}
                        />
                      </div>
                    </div>
                    <div aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" onClick={async () => {
                      await setSearchKeyword('')
                      redirectToPage(`/searched-campaigns/${searchKeyword}`)
                    }} className='col-2 px-0'>
                      <button className='btn bg-transparent'><i className='fa fa-search'></i></button>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  {user && user.id &&
                    <a className="dashboard" href={`${process.env.REACT_APP_BACKEND_BASE_URL}`}>Go to Dashboard</a>
                  }
                </li>
                <div className="mob-admin">
                  <li className={`li ${user && user.id ? "logout-btn" : ""}`}>
                    <a href="#!" className="btn  btn-outline-success" onClick={() => {
                      if (user && user.id && userToken) {
                        signOut()
                        logout();
                      } else {
                        history.push('/auth/login')
                      }
                    }}>
                      {user && user.id ? 'Logout' : 'Login'}
                    </a>
                  </li>
                  {!userToken &&
                    <li className="li">
                      <a href="#!" className="btn  btn-success" onClick={() => {
                        if (user && user.id) {
                          signOut()
                          logout();
                        } else {
                          history.push('/auth/signup')
                        }
                      }}>Sign up </a>
                    </li>
                  }
                </div>
              </ul>
            </div>
            {/* #endregion */}
          </div>
          <button onClick={() => history.push(
            // '/starting_fund'
            "/auth/signup"
          )} type="button" className="btn btn-success  mbl-nav-btn">Start fundraising</button>
        </nav>

      </header>
      <div className="header-space"></div>
    </div>
  );
};

export default connect(({ user, donateMoney }) => ({ user, donateMoney }), actions)(Header);